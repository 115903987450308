import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, fromEvent, interval, map, merge, of, startWith, switchMap } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../../../apps/site/src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class OnlineService {
	private readonly online = fromEvent(window, 'online').pipe(map(() => navigator.onLine));
	private readonly offline = fromEvent(window, 'offline').pipe(map(() => !navigator.onLine));
	private readonly http = inject(HttpClient);
	private readonly ws = webSocket({
		url: `${environment.url.base}:${environment.url.port}/ws`,
	});

	readonly isOnline = toSignal(
		interval(20000).pipe(
			switchMap(() =>
				merge(this.online, this.offline).pipe(
					startWith(navigator.onLine),
					switchMap((isOnline) => (isOnline ? of(isOnline) : this.ws.pipe(catchError(() => of(false))))),
				),
			),
		),
	);

	private checkServerConnection() {
		return this.http.get('https://rdpctd.dev:8000', { responseType: 'json' }).pipe(
			catchError(() => of(false)),
			map(() => true),
		);
	}

	readonly connectionInfo = toSignal(this.getConnectionInfo().pipe(startWith(this.getCurrentConnectionInfo())));

	private getNetworkInformationChanges() {
		const connection = (navigator as any).connection;
		if (connection && typeof connection.addEventListener === 'function') {
			return fromEvent(connection, 'change').pipe(map(() => navigator.onLine));
		}
		return of();
	}

	private getConnectionInfo() {
		const connection = (navigator as any).connection;
		if (!connection) {
			return of(this.getCurrentConnectionInfo());
		}

		return interval(10000).pipe(
			switchMap(() => fromEvent(connection, 'change').pipe(map(() => this.getCurrentConnectionInfo()))),
		);
	}

	private getCurrentConnectionInfo() {
		const connection = (navigator as any).connection;

		if (!navigator.onLine) {
			return {
				effectiveType: 'unknown',
				downlink: null,
				rtt: null,
			};
		}

		if (!connection) {
			return {
				effectiveType: 'unknown',
				downlink: null,
				rtt: null,
			};
		}

		return {
			effectiveType: connection.effectiveType || 'unknown',
			downlink: connection.downlink,
			rtt: connection.rtt,
		};
	}
}
