interface Url {
	base: string;
	port: number;
	graphqlRoute: string;
}
interface Environment {
	url: Url;
	production: boolean;
}

export const environmentProd: Environment = {
	url: {
		base: 'https://rdpctd.dev',
		graphqlRoute: '/graphql',
		port: 8000,
	},
	production: true,
};

export const environment = environmentProd;
