"use strict";

var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseV2Tag = exports.parseV1Tag = void 0;
const v1parser_1 = require("./parsers/v1parser");
exports.parseV1Tag = v1parser_1.default;
const v2parser_1 = require("./parsers/v2parser");
exports.parseV2Tag = v2parser_1.default;
function parse(bytes) {
  const v1data = (0, v1parser_1.default)(bytes);
  const v2data = (0, v2parser_1.default)(bytes);
  if (!v2data && !v1data) {
    return false;
  }
  const defaultValue = {
    version: false
  };
  const _a = v2data || defaultValue,
    {
      version: v2
    } = _a,
    v2meta = __rest(_a, ["version"]);
  const _b = v1data || defaultValue,
    {
      version: v1
    } = _b,
    v1meta = __rest(_b, ["version"]);
  const result = Object.assign(Object.assign({
    version: {
      v1,
      v2
    }
  }, v1meta), v2meta);
  /* tslint:disable:no-any */
  if (v1meta.comments) {
    result.comments = [{
      value: v1meta.comments
    }, ...(v2meta && v2meta.comments ? v2meta.comments : [])];
  }
  /* tslint:enable:no-any */
  return result;
}
exports.default = parse;