"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrameTypeValueMap = void 0;
const FRAME_TYPES = {
  /*
   * Textual frames
   */
  TALB: 'album',
  TBPM: 'bpm',
  TCOM: 'composer',
  TCON: 'genre',
  TCOP: 'copyright',
  TDEN: 'encoding-time',
  TDLY: 'playlist-delay',
  TDOR: 'original-release-time',
  TDRC: 'recording-time',
  TDRL: 'release-time',
  TDTG: 'tagging-time',
  TENC: 'encoder',
  TEXT: 'writer',
  TFLT: 'file-type',
  TIPL: 'involved-people',
  TIT1: 'content-group',
  TIT2: 'title',
  TIT3: 'subtitle',
  TKEY: 'initial-key',
  TLAN: 'language',
  TLEN: 'length',
  TMCL: 'credits',
  TMED: 'media-type',
  TMOO: 'mood',
  TOAL: 'original-album',
  TOFN: 'original-filename',
  TOLY: 'original-writer',
  TOPE: 'original-artist',
  TOWN: 'owner',
  TPE1: 'artist',
  TPE2: 'band',
  TPE3: 'conductor',
  TPE4: 'remixer',
  TPOS: 'set-part',
  TPRO: 'produced-notice',
  TPUB: 'publisher',
  TRCK: 'track',
  TRSN: 'radio-name',
  TRSO: 'radio-owner',
  TSOA: 'album-sort',
  TSOP: 'performer-sort',
  TSOT: 'title-sort',
  TSRC: 'isrc',
  TSSE: 'encoder-settings',
  TSST: 'set-subtitle',
  TXXX: 'user-defined-text-information',
  TYER: 'year',
  /*
   * URL frames
   */
  WCOM: 'url-commercial',
  WCOP: 'url-legal',
  WOAF: 'url-file',
  WOAR: 'url-artist',
  WOAS: 'url-source',
  WORS: 'url-radio',
  WPAY: 'url-payment',
  WPUB: 'url-publisher',
  /*
   * URL frames (<=2.2)
   */
  WAF: 'url-file',
  WAR: 'url-artist',
  WAS: 'url-source',
  WCM: 'url-commercial',
  WCP: 'url-copyright',
  WPB: 'url-publisher',
  /*
   * Comment frame
   */
  COMM: 'comments',
  USLT: 'lyrics',
  /*
   * Image frame
   */
  APIC: 'image',
  PIC: 'image',
  /**
   * User/owner/involved people frame
   */
  IPLS: 'involved-people-list',
  OWNE: 'ownership'
};
exports.default = FRAME_TYPES;
// Frame type to value map.
exports.FrameTypeValueMap = {
  TXXX: 'array',
  COMM: 'array',
  USLT: 'array'
};