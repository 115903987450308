import { Injectable } from '@angular/core';
import { parseV2Tag } from 'id3-parser';
import { fetchFileAsBuffer } from 'id3-parser/lib/util';
import { from, map, merge, Observable, switchMap, timer } from 'rxjs';

export interface Tags {
	title: string;
	author: string;
	duration: number;
}

@Injectable({
	providedIn: 'root',
})
export class AudioInfoParser {
	readTags(url: string, audioEl: HTMLAudioElement): Observable<Tags | null> {
		return from(fetchFileAsBuffer(url)).pipe(
			switchMap((buff) => {
				const data = parseV2Tag(buff);

				if (typeof data !== 'boolean') {
					return this.getCurrentTimeObservable(audioEl).pipe(
						map(
							(currentTime) =>
								({
									title: data.title,
									author: data.artist,
									duration: audioEl.duration - currentTime,
								}) as Tags,
						),
					);
				}
				return from([null]);
			}),
		);
	}

	private getCurrentTimeObservable(audio: HTMLAudioElement): Observable<number> {
		const timeUpdate$ = new Observable<number>((subscriber) => {
			const onTimeUpdate = () => subscriber.next(audio.currentTime);

			audio.addEventListener('timeupdate', onTimeUpdate);

			return () => audio.removeEventListener('timeupdate', onTimeUpdate);
		});

		const fallbackTimer$ = timer(0, 1000).pipe(map(() => audio.currentTime));

		return merge(timeUpdate$, fallbackTimer$);
	}
}
