"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchFileAsBuffer = exports.convertFileToBuffer = void 0;
function convertFileToBuffer(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(new Uint8Array(reader.result));
    };
    reader.onerror = e => reject(e);
    reader.readAsArrayBuffer(file);
  });
}
exports.convertFileToBuffer = convertFileToBuffer;
function fetchFileAsBuffer(url) {
  if (!url) {
    throw new Error('Argument should be a valid url string.');
  }
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = () => {
      if (request.response) {
        resolve(new Uint8Array(request.response));
      } else {
        reject(new Error('Empty XMLHttpRequest response.'));
      }
    };
    request.onerror = e => reject(e);
    request.send();
  });
}
exports.fetchFileAsBuffer = fetchFileAsBuffer;